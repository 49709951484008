module TagService {
    let app = angular.module("app");

    app.factory("TagService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Recupera i tag
            findTags: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/find-tags', {
            }, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                cancellable: true
            })
        };
        return serv;
    }]);
}